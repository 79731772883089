import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, defer, from } from 'rxjs';
import { Book } from 'src/app/models/book';
import { environment as env } from 'src/environments/environment';
import { map, timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as Books from "../../assets/db/books.json";

@Injectable({
  providedIn: 'root'
})
export class BookService {
  private baseUrl = `${env.apiHost}:${env.apiPort}/api/books`;

  constructor(private hc: HttpClient) { }

  getList(): Observable<Book[]> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<Book[]>(`${this.baseUrl}?sort=order|asc`, {
      headers : {
        's-t': session
      }
    }).pipe(
			timeout(5000),
			catchError(() => {
				const key = 'books';
				const mayBooks = localStorage.getItem(key);
				if (mayBooks) {
					const books = JSON.parse(mayBooks) as Book[];
					return of(books);
				} else {
					localStorage.setItem(key, JSON.stringify(Books.values));
					return of(Books.values as Book[]);
				}
			}),
			map(books => {
				localStorage.setItem('books', JSON.stringify(books));
				return books;
			})
		);
  }
  prior(id: string): Observable<Book> {
		return this.getList()
		.pipe(map((books) => {
      const sorted = books.sort((a, b) => {
        if (Number(a.order) < Number(b.order)) {
          return -1;
        } else if (Number(a.order) > Number(b.order)) {
          return 1;
        }
        return 0;
      });
      for (let i = 0 ; i < sorted.length; i++) {
        if (sorted[i]._id === id && i > 0) {
          return sorted[i - 1];
        }
      }
    }));
  }
  next(id: string): Observable<Book> {
		return this.getList()
		.pipe(map((books) => {
      const sorted = books.sort((a, b) => {
        if (Number(a.order) < Number(b.order)) {
          return -1;
        } else if (Number(a.order) > Number(b.order)) {
          return 1;
        } else {
          return 0;
        }
      });
      for (let i = 0 ; i < sorted.length; i++) {
        if (sorted[i]._id === id && i < (sorted.length - 1)) {
          return sorted[i + 1];
        }
      }
    }));
  }

  single(id: string): Observable<Book> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<Book>(`${this.baseUrl}/${id}`, {
      headers : {
        's-t': session
      }
    }).pipe(
			timeout(5000),
			catchError(() => {
				const key = 'books';
				const mayBooks = localStorage.getItem(key);
				if (mayBooks) {
					const books = JSON.parse(mayBooks) as Book[];
					of(books.find(elem => elem._id === id));
				} else {
					return of(null);
				}
			})
		);
  }
}
